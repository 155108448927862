import React from "react"
import Layout from "../components/common/Layout"
import Display from "../components/products/Display"
import Everthing, {
    Affiliate,
    AvandcedTv,
    BrandSurvey,
} from "../components/products/Everthing"
import Native from "../components/products/Native"
import ParallaxHeader from "../components/products/ParallaxHeader"
import ProductNav from "../components/products/ProductNav"
import SearchContent from "../components/products/SearchContent"
import SocialContent from "../components/products/SocialContent"
import "../css/font-icons.css"

const Products = () => {
    return (
        <Layout>
            <ParallaxHeader />
            <ProductNav />
            <Display />
            <Everthing />
            <Native />
            <BrandSurvey />
            <SocialContent />
            <SearchContent />
            <AvandcedTv />
            <Affiliate />
        </Layout>
    )
}

export default Products
