import React from "react"
import styles from "../../css/products.module.css"

const HeadingBlock = ({
    heading,
    desc,
    textAlign = "center",
    descStyle = {},
    titleStyle = {},
}) => {
    return (
        <div className={`${styles.headingBlock} text-${textAlign}`}>
            <h2 className={styles.evTitle} style={titleStyle}>{heading}</h2>
            {desc && (
                <div className={styles.evDesc} style={descStyle}>
                    {desc}
                </div>
            )}
            <span className={styles.headingBlockAfter}></span>
        </div>
    )
}

export default HeadingBlock
