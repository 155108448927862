import React from "react"
import HeadingBlock from "./HeadingBlock"
import styles from "../../css/products.module.css"
import { targets } from "./ProductNav"

const Native = () => {
    return (
        <>
            <section className="pt-5 pb-5" id={targets.native}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mb-3 text-center">
                            <img
                                src="/images/products/nativegif.gif"
                                class="img-fluid"
                            />
                        </div>
                        <div className="col-lg-7 mb-3">
                            <HeadingBlock
                                heading="NATIVE ADVERTISING"
                                desc="Native advertising (or “in-feed advertising”) is a new hybrid ad format often considered to be the next generation of standard display. Advertisers simply submit their image asset (and video if applicable) along with a title, description, logo and URL."
                                textAlign="left"
                                descStyle={{ fontSize: "18px" }}
                            />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul className={styles.nativeList}>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Boost awareness performance
                                        </li>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Scale video across all channels
                                        </li>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Tell compelling stories
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className={styles.nativeList}>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Transparency
                                        </li>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Consistent workflow
                                        </li>
                                        <li>
                                            <i className="icon-caret-right" />{" "}
                                            Holistic channel management
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-5 bg-light"  id={targets.mobile}>
                <div className="container">
                    <HeadingBlock
                        heading="MOBILE"
                        desc="Billiontags turnkey tactics enable Cross-device, Hyperlocal and App User Acquisition — for a user-centric approach to media spend."
                    />
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="text-center">
                                <img
                                    src="/images/products/m1.png"
                                    className={styles.featureImg}
                                    height="88px"
                                />
                                <p className="f-18">
                                    Dynamic Creative Optimization
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-center">
                                <img
                                    src="/images/products/m2.png"
                                    className={styles.featureImg}
                                />
                                <p className="f-18">
                                    Hyperlocal geo-location capability
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-center">
                                <img
                                    src="/images/products/m3.png"
                                    className={styles.featureImg}
                                />
                                <p className="f-18">Audience Analytics</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-center">
                                <img
                                    src="/images/products/m4.png"
                                    className={styles.featureImg}
                                />
                                <p className="f-18">
                                    Private Marketplace (PMP)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section  id={targets.email}>
                <div className="container-fluid">
                    <div className="row align-items-stretch">
                        <div className="col-md-6 p-md-0">
                            <div
                                className="p-md-5 pt-5 pb-5"
                                style={{
                                    backgroundColor: `rgb(245, 246, 247)`,
                                    lineHeight: "30px"
                                }}
                            >
                                <HeadingBlock
                                    heading="E-MAIL"
                                    textAlign="left"
                                    titleStyle={{ marginBottom: "0px" }}
                                />
                                <p>
                                    Send Personalized Messages and Drive
                                    Conversions with LinkedIn Sponsored InMail.
                                    Generate Leads. Drive Brand Awareness. 500M+
                                    Member Reach. Promote Content. Types:
                                    Sponsored Content, Sponsored InMail, Dynamic
                                    Ads, Display Ads, Text Ads. Deliver
                                    relevant, triggered email marketing messages
                                    in a matter of minutes with our easy-to-use,
                                    drag-and-drop interface designed for
                                    marketers like you. Respond to customer
                                    journeys on the fly without waiting for your
                                    programmers to make the changes you need.
                                    With rich profiles of your customers all in
                                    a single place, create relevant
                                    conversations with each of them, based on
                                    their individual behaviors, preferences, and
                                    demographics, in real time. You can generate
                                    segments of one…at scale.
                                </p>
                            </div>
                        </div>
                        <div className={`col-md-6 p-0`}>
                            <div className={styles.emailBg} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Native
