import React from "react"
import styles from "../../css/products.module.css"
import HeadingBlock from "./HeadingBlock"
import { targets } from "./ProductNav"

const Item = ({ title, icon, desc, iconSize = "24px" }) => {
    return (
        <div className="col-md-4 col-sm-6 mb-3">
            <div className="d-flex align-items-center mb-2">
                <div className="mr-2">
                    <img
                        height={iconSize}
                        width={iconSize}
                        src={`/images/products/${icon}`}
                    />
                </div>
                <h6 className="font-weight-bold text-uppercase f-14 m-0">
                    {title}
                </h6>
            </div>
            <p className="f-14 text-black-50">{desc}</p>
        </div>
    )
}

const Everthing = () => {
    return (
        <section className="pt-5 pb-5 bg-light" id={targets.video}>
            <div className="container">
                <HeadingBlock
                    heading="Everything You Need to Succeed"
                    desc="A complete video on demand platform to get your audience
                    hooked with artistic experience."
                />
                <div className="row">
                    <Item
                        title="Online Video Platform"
                        desc="Store, stream, entertain and leverage on every
                        screen to monetize your video content with ease."
                        icon="ic1.png"
                    />
                    <Item
                        title="Video Content Management"
                        desc="Ensure in managing every piece of content through an
                        integrated dynamic system to amplify your
                        productivity."
                        icon="ic2.png"
                    />
                    <Item
                        title="Video Delivery"
                        desc="A rock-hard video distribution strategy to perform
                        in delivering a rich quality and delay-free content
                        experience."
                        icon="ic3.png"
                    />
                    <Item
                        title="Reliable Video Hosting"
                        desc="Harnessing the power of upload, transcode and secure
                        hosting of your video content with flabbergasting
                        pivotal features."
                        icon="ic4.png"
                    />
                    <Item
                        title="Video Player"
                        desc="Encompassed with comprehensive API integration and
                        custom HTML5 toolkit to embed video content across a
                        diverse platforms."
                        icon="ic5.png"
                    />
                    <Item
                        title="100% Customizable"
                        desc="Pick your choices! customize your Vod platform with
                        awesome integrations that resembles beyond your
                        expectation &amp; experience great headway features."
                        icon="ic5.png"
                    />
                </div>
            </div>
        </section>
    )
}

export const Affiliate = () => {
    return (
        <section className="pt-5 pb-5"  id={targets.affiliate}>
            <div className="container">
                <HeadingBlock
                    heading="AFFILIATE"
                    desc="Affiliate marketing promotes discovery and drives revenue."
                />
                <div className="row">
                    <Item
                        title="QUALITY"
                        desc="Comprehensive screening and monitoring ensure the quality of your affiliate program partners."
                        icon="affiliate1.png"
                        iconSize="24px"
                    />
                    <Item
                        title="TECHNOLOGY"
                        desc="Products and tools that make affiliate marketing easier to implement, measure and optimize."
                        icon="affiliate2.png"
                        iconSize="24px"
                    />
                    <Item
                        title="SERVICE"
                        desc="Best-in-class account management with strategic recommendations to grow your business."
                        icon="affiliate3.png"
                        iconSize="24px"
                    />
                    <Item
                        title="ATTRIBUTION & INSIGHTS"
                        desc="Access to a dedicated team of analysts and Cadence, our integrated marketing insights and attribution platform."
                        icon="affiliate4.png"
                        iconSize="24px"
                    />
                    <Item
                        title="REACH"
                        desc="Our affiliate network can process payments to 202 countries/regions in 25 currencies."
                        icon="affiliate5.png"
                        iconSize="24px"
                    />
                    <Item
                        title="CAMPAIGN MANAGEMENT"
                        desc="Comprehensive program management, influencer recruitment, detailed reporting and actionable insights for each campaign."
                        icon="affiliate6.png"
                        iconSize="24px"
                    />
                </div>
            </div>
        </section>
    )
}

export const AvandcedTv = () => {
    return (
        <>
            <div className="container-fluid" id={targets.content}>
                <div className="row align-items-stretch">
                    <div
                        className="col-md-4 p-0"
                        style={{ backgroundColor: "rgb(231, 76, 60)" }}
                    >
                        <div className={styles.tvColorBox}>
                            <h3>CREATE BEAUTIFUL CONTENT</h3>
                            <p>
                                Create beautiful interactive content proven to
                                drive reader engagement.
                            </p>
                        </div>
                    </div>
                    <div
                        className="col-md-4 p-0"
                        style={{ backgroundColor: "rgb(52, 73, 94)" }}
                    >
                        <div className={styles.tvColorBox}>
                            <h3>Publish the Content</h3>
                            <p>
                                Publish your content seamlessly across social,
                                email, mobile and website.
                            </p>
                        </div>
                    </div>
                    <div
                        className="col-md-4 p-0"
                        style={{ backgroundColor: "rgb(231, 76, 60)" }}
                    >
                        <div className={styles.tvColorBox}>
                            <h3>Measure performance</h3>
                            <p>
                                Measure content performance with live analytics.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pt-5 pb-5"  id={targets.advanced}>
                <div className="container">
                    <HeadingBlock
                        heading="ADVANCED TV"
                        desc="Activate your audience on connected devices in premium programming"
                    />
                    <div className="text-center mb-5">
                        <img
                            className="img-fluid"
                            src="/images/products/advancedtv.png"
                            alt=""
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-4 d-flex mb-3">
                            <div className={styles.tvCount}>1.</div>
                            <p className={styles.tvDesc}>
                                LEAN-BACK, FULL SCREEN EXPERIENCE
                            </p>
                        </div>
                        <div className="col-md-4 d-flex mb-3">
                            <div className={styles.tvCount}>2.</div>
                            <p className={styles.tvDesc}>
                                DELIVERS HIGH COMPLETION RATES
                            </p>
                        </div>
                        <div className="col-md-4 d-flex mb-3">
                            <div className={styles.tvCount}>3.</div>
                            <p className={styles.tvDesc}>
                                CLOSEST DIGITAL FORMAT TO TV COMMERCIALS
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.fullbar} />
        </>
    )
}

export const BrandSurvey = () => {
    return (
        <>
            <section className="pt-5 pb-5"  id={targets.brandSurveys}>
                <div className="container">
                    <HeadingBlock
                        heading="BRAND SURVEY"
                        desc="Deploy custom brand surveys to gauge ad effectiveness and measure brand lift"
                    />
                </div>
            </section>
            <div className={styles.brandSurvey}></div>
        </>
    )
}

export default Everthing
