import React from "react"
import HeadingBlock from "./HeadingBlock"
import styles from "../../css/products.module.css"
import { targets } from "./ProductNav"

const FeatureItem = ({
    desc = "",
    icon = "icon-line-monitor",
    title = "",
    isRight = false,
}) => {
    return (
        <div className="mb-5">
            <div className="d-flex">
                <div
                    className={`${styles.socialIcon} order-0 order-lg-${
                        isRight ? "1" : "0"
                    } mr-3 mr-lg-0 m${isRight ? "l" : "r"}-lg-3`}
                >
                    <i className={icon} />
                </div>
                <div className="order-0">
                    <h6 className="font-weight-bold text-uppercase f-14 m-0">
                        {title}
                    </h6>
                    <p className="text-black-50">{desc}</p>
                </div>
            </div>
        </div>
    )
}

const SocialContent = () => {
    return (
        <section className="pt-5 pb-5" id={targets.social}>
            <div className="container">
                <HeadingBlock heading="SOCIAL" desc="Join the Conversation" />
                <div className="row mb-3-sm">
                    <div className="col-lg-4 col-md-6 mb-3">
                        <FeatureItem
                            icon="icon-facebook"
                            title="Strategy"
                            desc="Leverage analysis of historical events in
                            conjunction with real time coverage and audience
                            insights, to create social strategies that drive
                            ROI"
                            isRight
                        />
                        <FeatureItem
                            icon="icon-twitter"
                            title="Content"
                            desc="Craft powerful marketing strategies with
                            Amobee’s Content Curator, a tool that helps you
                            quickly develop messaging designed for maximum
                            impact"
                            isRight
                        />
                        <FeatureItem
                            icon="icon-linkedin"
                            title="War Rooms"
                            desc="Assemble a dedicated team of Amobee Brand
                            Intelligence and social media experts to monitor
                            important events for trending content, in real
                            time"
                            isRight
                        />
                    </div>
                    <div className="col-lg-4 d-none d-lg-block mb-3 text-center">
                        <img
                            src="/images/products/socialbg.png"
                            alt="iphone 2"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-3">
                        <FeatureItem
                            icon="icon-pinterest"
                            title="Dynamic Targeting"
                            desc="Dynamically optimize interest keyword targeting
                            with social &amp; web listening data from an
                            extensive collection of cross-channel digital
                            sources"
                        />
                        <FeatureItem
                            icon="icon-gplus"
                            title="Influence"
                            desc="Tap into the power of inﬂuencer marketing with
                            Amobee’s proprietary inﬂuencer reach programs"
                        />
                        <FeatureItem
                            icon="icon-vimeo"
                            title="Dedicated Social Team"
                            desc=" We are among an elite group with API level
                            access to Instagram, Facebook, Twitter,
                            Pinterest &amp; Snapchat."
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialContent
