import React from "react"
import HeadingBlock from "./HeadingBlock"
import { targets } from "./ProductNav"

const FeatureItem = ({ desc = "", icon = "icon-line-monitor", title = "" }) => {
    return (
        <div className="mb-5">
            <div className="d-flex align-items-center">
                <div className="fbox-icon mr-3" style={{ fontSize: "28px" }}>
                    <i className={icon} />
                </div>
                <h6 className="font-weight-bold text-uppercase f-14 m-0">
                    {title}
                </h6>
            </div>
            <p className="text-black-50">{desc}</p>
        </div>
    )
}

const SearchContent = () => {
    return (
        <section className="pt-5 pb-5 bg-light"  id={targets.search}>
            <div className="container">
                <HeadingBlock
                    heading="SEARCH"
                    desc="The Rakuten Marketing Search Difference"
                />
                <div className="row align-items-center">
                    <div className="col-lg-4 mb-3 text-center">
                        <img
                            src="/images/products/searchimg.gif"
                            alt="iPhone"
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 topmargin mb-3">
                        <FeatureItem
                            icon="icon-line-monitor"
                            title="Unique Data"
                            desc="Proprietary audience and product data, along
                            with custom audience segmentation optimize your
                            search strategy."
                        />
                        <FeatureItem
                            icon="icon-line-eye"
                            title="Partnership"
                            desc="As a Google Premier Partner and Bing Preferred
                            Partner, we have exclusive access to training,
                            marketing and technology support."
                        />
                        <FeatureItem
                            icon="icon-line-power"
                            title="Service"
                            desc="Best-in-class account management with
                            strategic recommendations to grow your
                            business."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 topmargin mb-3">
                        <FeatureItem
                            icon="icon-line-layers"
                            title="Performance"
                            desc="Proven results through unique consumer data,
                            proprietary algorithms, cross-device
                            targeting and premium placements."
                        />
                        <FeatureItem
                            icon="icon-line-star"
                            title="Bidding"
                            desc="Advanced bidding algorithms and unique data
                            create cost savings and optimize your
                            bidding strategy for optimal performance."
                        />
                        <FeatureItem
                            icon="icon-line-anchor"
                            title="Search Insights"
                            desc="Visibility into consumer journey data
                            provides insight into how search performs in
                            concert with other marketing channels."
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SearchContent
