import React from "react"
import styles from "../../css/products.module.css"
import { Link } from "react-scroll"

export const targets = {
    display: "Display",
    video: "Video",
    native: "Native",
    mobile: "Mobile",
    email: "Email",
    brandSurveys: "Brand-Surveys",
    social: "Social",
    search: "Search",
    content: "Content",
    advanced: "Advanced TV",
    content: "Content",
    dooh: "DOOH",
    affiliate: "Affiliate",
}

const sections = [
    {
        title: "Display",
        target: targets.display,
    },
    {
        title: "Video",
        target: targets.video,
    },
    {
        title: "Native",
        target: targets.native,
    },
    {
        title: "Mobile",
        target: targets.mobile,
    },
    {
        title: "Email",
        target: targets.email,
    },
    {
        title: "Brand surveys",
        target: targets.brandSurveys,
    },
    {
        title: "Social",
        target: targets.social,
    },
    {
        title: "Search",
        target: targets.search,
    },
    {
        title: "Content",
        target: targets.content,
    },
    {
        title: "Advanced TV",
        target: targets.advanced,
    },
    {
        title: "Affiliate",
        target: targets.affiliate,
    },
]

const NavItem = ({ title, target }) => {
    return (
        <li>
            <Link smooth activeClass={styles.navListActive} spy={true} offset={-100} to={target}>{title}</Link>
        </li>
    )
}

const ProductNav = () => {
    return (
        <nav className={styles.navContainer}>
            <ul className={styles.navList}>
                {sections.map(item => (
                    <NavItem {...item} key={item.target} />
                ))}
            </ul>
        </nav>
    )
}

export default ProductNav
