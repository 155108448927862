import React, { useState } from "react"
import HeadingBlock from "./HeadingBlock"
import { targets } from "./ProductNav"
import styles from "../../css/products.module.css"

const Feature = ({ icon, title }) => {
    return (
        <div className="col-lg-3 text-center">
            <img
                src={`/images/products/${icon}.png`}
                className={styles.featureImg}
            />
            <p>{title}</p>
        </div>
    )
}

const Display = () => {
    const [tab, setTab] = useState("Features")

    const TabLink = ({ title = "", icon = "icon-screen" }) => {
        return (
            <li
                className={`list-group-item ${styles.tabLink} ${
                    title === tab ? styles.tabLinkActive : ""
                }`}
                onClick={() => setTab(title)}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <i className={icon} />
                        <div className="ml-2">{title}</div>
                    </div>
                    <i
                        className={`icon-chevron-right ${styles.tabLinkRight}`}
                    />
                </div>
            </li>
        )
    }

    const TabPane = ({ title, children }) => {
        if (tab === title) {
            return children
        }
        return null
    }

    return (
        <section className="pt-5 pb-5" id={targets.display}>
            <div className="container">
                <HeadingBlock
                    heading="DISPLAY"
                    desc="With over 60 billion global display impressions daily across high-impact inventory, you can pick the best opportunity to engage with your audience."
                />
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <ul className="list-group">
                            <TabLink title="Features" />
                            <TabLink title="Benefits" icon="icon-magic" />
                            <TabLink
                                title="Optimize Your Campaign"
                                icon="icon-tint"
                            />
                            <TabLink
                                title="Protect the Integrity"
                                icon="icon-gift"
                            />
                        </ul>
                    </div>
                    <div className="col-md-8  mb-3">
                        <TabPane title="Features">
                            <div className="row">
                                <Feature icon="icon1" title="Impact Ads" />
                                <Feature
                                    icon="icon2"
                                    title="Private Marketplace (PMP)"
                                />
                                <Feature
                                    icon="icon3"
                                    title="Audience Analytics"
                                />
                                <Feature icon="icon4" title="Brand Safety" />
                            </div>
                        </TabPane>
                        <TabPane title="Benefits">
                            <h3 className={styles.tabTitle}>Benefits</h3>
                            <ul className="pl-4">
                                <li>
                                    Precision targeting to most relevant online
                                    audiences.
                                </li>
                                <li>
                                    New insights into the most responsive
                                    audiences to optimize your campaign
                                    accordingly.
                                </li>
                                <li>
                                    Screened inventory through customized site
                                    lists, third-party validation and
                                    viewability requirements
                                </li>
                                <li>
                                    Proprietary relationships with Premium
                                    Publishers
                                </li>
                                <li>
                                    Campaign messaging that can dynamically
                                    change based on external triggers and events
                                </li>
                            </ul>
                        </TabPane>
                        <TabPane title="Optimize Your Campaign">
                            <h3 className={styles.tabTitle}>
                                Rich Media Banners
                            </h3>
                            <p>
                                Increase engagement by using Amobee 3D, IAB
                                Rising Stars, over-the-page, floating, page
                                takeovers, and expandable ad formats. Use Amobee
                                DSP to set up all of your display media
                                purchases. No matter how many different
                                inventory sources, you can optimize performance
                                and spend your budget wisely across all of them
                                at once.
                            </p>
                        </TabPane>
                        <TabPane title="Protect the Integrity">
                            <h3 className={styles.tabTitle}>
                                Optimize &amp; Protect Campaign
                            </h3>
                            <p>
                                Optimize your campaign for the best-possible
                                ROI. Amobee optimization capabilities are
                                unmatched in the industry. Avoid running ads
                                next to content that conflicts with your brand
                                values. With Amobee, you can integrate data from
                                leading contextual providers as you set up your
                                campaign.
                            </p>
                        </TabPane>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Display
